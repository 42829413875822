.inputType {
    position: relative;
}

.picture {
    position: absolute;
    right: 20px;
    top: 19px;

}

label {
    position: absolute;
    color: #222;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    top: -8px;
    left: 16px;
    background: #fff;
}

.inputType2 {
    position: relative;
}

.picture2 {
    position: absolute;
    right: 20px;
    top: 19px;

}

.main-reffrel1 {
    // background-color: #fff;
    // display: flex;
    // align-items: center;
    // height: 100vh;

    min-height: 100vh;
    background: url(../../src/Assets/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .main_box {
        background: #FFFFFF;
        box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.05);
        border-radius: 30px;
        width: 100% !important;
        margin: auto;

    }

    .inner-logo {
        img {
            width: 150px;
        }
    }

    .cmn-tile-style {
        background-color: #fff;
        border-radius: 10px;
        padding: 30px;
        margin-top: 10px;

        h2 {
            font-size: 30px;
        }

        h6 {
            font-weight: normal;
            color: #636363;
            text-align: center;

        }

        label {
            // // font-family: 'Nunito'!important;
            // font-style: normal!important;
            // font-weight: bold!important;
            // font-size: 16px!important;
            // line-height: 22px!important;
            // color: #000000!important;
            // // padding-top: 26px;
        }

        input {
            // background: #FAFAFB;
            // border: 1px solid #EFEFEF;
            // box-sizing: border-box;
            // border-radius: 5px;
            // height: 50px;
            // opacity: 1;
            // visibility: visible;
            border-radius: 10px;
            // border: 1.5px solid var(--primary-blue, #3456D0);
            border: 1.5px solid #E1ECFF;
            background: #FFF;
            padding: 18px;
            width: 100%;
            color:#3456D0;
            padding-right: 62px;

            &::placeholder {
                color: #CAD3E2;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            &:hover {
                border: 1.5px solid #3456D0 !important;
            }
        }
    }

    .inputErrors {
        color: red;
    }
}

.log-img {
    text-align: center;
    margin-top: 75px;
    margin-bottom: 40px;
}

@media only screen and (max-width: 600px) {
    .main-reffrel1 {
        .main_box {
            width: 90% !important;
        }

        .cmn-tile-style {
            padding: 2px !important;
        }
    }
}