html {
  scroll-behavior: smooth;
}

body {
 font-family: 'Poppins', sans-serif;
  // background-color: #201854 !important;
}


.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}


ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

h1 {
  font-size: 80px !important;
  font-style: normal !important;
}

.grey {
  color: #8CA0B9 !important;
}


.ptb20 {
  padding: 20px 0;
}

h2 {
  font-size: 42px !important;
  font-style: normal !important;
}

p {
  font-size: 16px !important;
}

.btn-common {
  // background: linear-gradient(
  //   180deg, #133572 0%, #3568C1 100%);
  //       border-radius: 10px;
  //       margin: 10px 0;
  //       padding: 12px 30px;
  //       color: #fff;
  //       text-decoration: none;
  //       border: none;
  //       font-size: 13px;
  //       width: 100%;
  border-radius: 50px;
  background: var(--primary-button-style, linear-gradient(90deg, #3553D5 0%, #0C194E 100%));
  padding: 18px 50px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  width: 100%;
}

.btn-common1 {
  background: linear-gradient(180deg, #133572 0%, #3568C1 100%);
  border-radius: 10px;
  margin: 10px 0;
  padding: 12px 30px;
  color: #fff;
  text-decoration: none;
  border: none;
  font-size: 13px;
  width: 100%;
  pointer-events: none;
  opacity: 0.4;
}



.ptb {
  padding: 60px 0;
}

.yellow {
  color: #E09414;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #E09414;
}

.green {
  color: #6DFF39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}


.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}

.reset_heading {
  // font-family: Nunito;
  // font-style: normal;
  // font-weight: 800;
  // font-size: 25px;
  // color: #030229;
  color: #222;
  // text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 15px;
}

.reset_para {
  // font-family: Nunito;
  // font-style: normal;
  // font-weight: normal;
  // font-size: 16px;
  // line-height: 22px;
  // color: #656565;
  color: #515151;
  font-family: Poppins;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 40px;
}

.bar_bar_main {
  display: flex;
}

.bar_bar {
  width: 16%;
  height: 4px;
  margin-top: 5px;
  margin-bottom: 18px;
  background: linear-gradient(180deg, #133572 0%, #3568C1 100%);
  border-radius: 4px;
}

.bar_bar1 {
  margin-left: 5px;
  width: 3%;
  height: 4px;
  margin-top: 5px;
  margin-bottom: 18px;
  background: linear-gradient(180deg, #133572 0%, #3568C1 100%);
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {

  .reset_heading {
    font-size: 22px !important;
  }

  .reset_para {
    font-size: 14px !important;
  }
}


// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .nav-brand {
    margin-left: 15px;
  }


  h1 {
    font-size: 3.5rem !important;
  }

  h2 {
    font-size: 2.5rem !important;
    font-weight: bold;
  }

  .ptb {
    padding: 20px 0;
  }
}

// 12 pro max 
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .nav-brand {
    margin-left: 15px;
  }


  h1 {
    font-size: 3.5rem !important;
  }

  h2 {
    font-size: 2.5rem !important;
    font-weight: bold;
  }

  .ptb {
    padding: 20px 0;
  }
}

// .main_box{
//   background: #FFFFFF;
//   box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.05);
//   border-radius: 30px; 
//   width: 24%;
//   margin: auto;
// }